import { Component } from "react";

import PageTitle from "../components/page_title/PageTitle";
// import { Link } from "react-router-dom";

import Masonry from "masonry-layout";

import CornerstonesCard from "./NoticeBoard/CornerstonesCard";
import DECCard from "./NoticeBoard/DECCard";
import GirlguidingCard from "./NoticeBoard/GirlguidingCard";
import RedBookCartCard from "./NoticeBoard/RedBookCartCard";
// import TheToddlerGroupCard from "./NoticeBoard/TheToddlerGroupCard";

import "./NoticeBoard.css";

// noinspection HtmlUnknownTarget
class NoticeBoardPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);

    let elem = document.querySelector(".grid");
    // eslint-disable-next-line no-unused-vars
    let msnry = new Masonry(elem, {
      itemSelector: ".grid-item",
    });
  }

  render() {
    return (
      <section id="news-page">
        <PageTitle text="Community Notice Board" />

        <div className="fullwidth-block">
          <article className="container">
            <div className="row grid" data-masonry='{"percentPosition": true }'>
              <div className="grid-item col-sm-12 col-md-6 col-lg-4 py-3">
                <GirlguidingCard />
              </div>
              <div className="grid-item col-sm-12 col-md-6 col-lg-4 py-3">
                <CornerstonesCard />
              </div>
              {/* <div className="grid-item col-sm-12 col-md-6 col-lg-4 py-3">
                            	<TheToddlerGroupCard />
                       		</div> */}
              <div className="grid-item col-sm-12 col-md-6 col-lg-4 py-3">
                <RedBookCartCard />
              </div>
              <div className="grid-item col-sm-12 col-md-6 col-lg-4 py-3">
                <DECCard />
              </div>
              {/* <div className="grid-item col-sm-12 col-md-6 col-lg-4 py-3">
                            	<div className="card border-primary">
                                	<div className="card-body">
                                    	<h3 className="card-title">Card</h3>
                                    	<p className="card-text">With supporting text below as a natural lead-in to additional content and more supporting text below ... content. additional content.</p>
                                    	<Link to="#" className="btn btn-outline-secondary">Outline</Link>
                                	</div>
                            	</div>
                        	</div>
                        	<div className="grid-item col-sm-12 col-md-6 col-lg-4 py-3">
                            	<div className="card border-primary">
                                	<div className="card-body">
                                    	<h3 className="card-title">Card</h3>
                                    	<p className="card-text">With more more more supporting text.</p>
                                    	<Link to="#" className="btn btn-outline-secondary">Outline</Link>
                                	</div>
                            	</div>
                        	</div>
                        	<div className="grid-item col-sm-12 col-md-6 col-lg-4 py-3">
                            	<div className="card border-primary">
                                	<div className="card-body">
                                    	<h3 className="card-title">Card</h3>
                                    	<p className="card-text">With supporting text.</p>
                                    	<Link to="#" className="btn btn-outline-secondary">Outline</Link>
                                	</div>
                            	</div>
                        	</div> */}
            </div>
          </article>
        </div>
      </section>
    );
  }
}

export default NoticeBoardPage;
